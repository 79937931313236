(function ($) {
  // @todo finalize sorting and filtering
  Drupal.behaviors.productGrid = {
    attached: false,

    attach: function (context, settings) {
      /*
       * Allow other modules to be called before carousel gets inited.
       */
      var plug = this;
      var _context = context;
      var _settings = settings;
      var attachFunc = function () {
        if (plug.attached) {
          return;
        }
        plug.attachReal(_context, _settings);
        plug.attached = true;
      };

      $(document).trigger('mpp-product-grid--init', attachFunc);
      if (!plug.attached) {
        attachFunc();
      }
    },

    attachReal: function (context, settings) {
      var $grids = $('.product-grid', context);
      var $carouselGrids = $grids.filter('.product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      var $carouselsItems = $('.js-grid-item', $carousels);
      var minSlidesToShow = 2;
      // var sortPlaceholder = '<div class="js-product-grid-sort-placeholder" />';
      var hasQuickshop = $grids.hasClass('product-grid--quickshop');
      var $wrapper = $('.product-grid-wrapper', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      // @setup - new brand to adjust responsive/dots settings per designs
      $carousels.each(function () {
        if ($carouselsItems.length <= minSlidesToShow) {
          return;
        }
        var arrowsDiv = $(this).parent().find('.carousel-controls');
        var dotsDiv = $(this).parent().find('.carousel-dots');
        var slides_to_show = JSON.parse($(this).closest('.js-product-grid').attr('data-grid-items-per-row'));

        if (!slides_to_show.large) {
          slides_to_show.large = slides_to_show.medium;
        }

        var settings = {
          appendArrows: arrowsDiv,
          infinite: true,
          slidesToShow: slides_to_show.large,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: dotsDiv,
                arrows: true,
                dots: false,
                slidesToShow: slides_to_show.medium,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                appendDots: dotsDiv,
                arrows: true,
                dots: true,
                slidesToShow: slides_to_show.small,
                slidesToScroll: 1
              }
            }
          ]
        };
        // slidesToShow override
        var slidesToShowCount = $(this).data('slides-to-show');

        if (slidesToShowCount) {
          settings.slidesToShow = slidesToShowCount;
        }

        // Init this carousel with our settings
        $(this).slick(settings);

        // On before slide change
        $(this).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          // Remove quickshop:
          if (hasQuickshop && Drupal.behaviors.quickshop) {
            $('.js-quickshop', $wrapper).remove();
          }
        });
      });

      var collectGridProductIds = function ($gridItems) {
        var gridItems = $.makeArray($gridItems);
        var allProductIds = $.map(gridItems, function (cell) {
          return $(cell).data('product-id');
        });

        return allProductIds;
      };
      var sortGrid = function (sortFn) {
        var $allProductCells = $('.js-product-grid-item');
        var allProductIds = collectGridProductIds($allProductCells);

        allProductData = [];
        _.each(allProductIds, function (prodId, i) {
          allProductData.push(prodcat.data.getProduct(prodId));
        });
        // console.log(_.pluck(allProductData, "PRICE"));
        var sortedProductData = _.sortBy(allProductData, sortFn);
        // console.log(_.pluck(sortedProductData, "PRICE"));
        var sortedCells = [];

        _.each(sortedProductData, function (prod, i) {
          if (prod) {
            sortedCells.push($('.js-product-grid-item[data-product-id=' + prod.PRODUCT_ID + ']'));
          }
        });
        var $grid = $('.js-product-grid');

        $grid.empty();
        _.each(sortedCells, function (cell, i) {
          $grid.append($(cell));
        });
      };

      $(document).on('mpp_sort:selected', function (e, sortFn) {
        var $productGrid = $('.js-product-grid', context);

        sortGrid(sortFn);
        site.util.grids.equalHeightsGrids($productGrid);
      });

      // Quickshop Hover State on Desktop
      //      $grids.on( 'mouseover','.product-brief__container', function() {
      //        $(this).find('.product-brief__quickshop-launch').addClass('qs-active');
      //      }).on( 'mouseout','.product-brief__container', function() {
      //        $(this).find('.product-brief__quickshop-launch').removeClass('qs-active');
      //      });
      // Quickshop Hover State on Desktop

      // Filter event:
      //       $grids.on('productGrid.filter', function(event, prodsToShow, sort) {
      //         var $items = $('.js-product-grid-item', this);
      //         var $filteredItems = $items.filter(function() {
      //           return _.contains(prodsToShow, $(this).data('product-id'));
      //         });
      //         var $grid = $(this);
      //         var $container = $items.first().parent();
      //
      //         // First, undo any previous sorting we may have done:
      //         _resetFilterSort($grid);
      //
      //         // (Optionally) Sort:
      //         if (sort) {
      //           // Put a placeholder before the items we're going to sort so we can
      //           // un-sort them later (_resetFilterSort).
      //           $filteredItems.before(sortPlaceholder);
      //           // Reverse the array because we're prepending. Appending also works,
      //           // but this way we can target :first-child in css to get the primary
      //           // result in regimens.
      //           _.each(_.clone(prodsToShow).reverse(), function(id) {
      //             var $item = $filteredItems.filter('[data-product-id="' + id + '"]');
      //             $item.prependTo($container);
      //           });
      //         }
      //
      //         // Filter:
      //         $items.addClass('hidden');
      //         $filteredItems.removeClass('hidden');
      //
      //         $(this).trigger('grid.reflow');
      //       });

      // Reset filter event:
      //       $grids.on('productGrid.showAll', function() {
      //         _resetFilterSort($(this));
      //         $('.js-product-grid-item', this).removeClass('hidden');
      //
      //         $(this).trigger('grid.reflow');
      //       });
    }
  };

  //   function _resetFilterSort($grid) {
  //     var $items = $('.js-product-grid-item', $grid);
  //     $('.js-product-grid-sort-placeholder', $grid).each(function() {
  //       var id = $(this).data('placeholder-product-id');
  //       var $item = $items.filter('[data-product-id="' + id + '"]');
  //       $(this).after($item).remove();
  //     });
  //   }
})(jQuery);

// old functions preserved below
// @todo cleanup after reviewing with jeremy
/*
(function($){
  Drupal.behaviors.productGrid = {
    attach: function(context) {
      var filterData = function(filterBy, val, allProductIds) {
        var filteredProductIds = [];
        filteredProductIds = _.filter(allProductIds, function(prodId, i) {
          var prodData = prodcat.data.getProduct(prodId);
          var vals = prodData[filterBy];
          if (!vals) return null;
          vals = vals.split(",");
          return _.contains(vals, val);
        });
        return (filteredProductIds);
      }
      var filterGrid = function(filterBy, val) {
        var $allProductCells = $(".js-product-grid-item");
        $allProductCells.show();
        if (val.length <1) return null;
        var allProductIds = $(".js-product-grid-item").map(function() {
          return $(this).data('product-id');
        });
        var filteredProductIds = filterData(filterBy, val, allProductIds);
        if (filteredProductIds.length <1) {
          alert ("No matches");
        } else {
          $allProductCells
              .show()
              .not(function() {
                $gridItem = $(this);
                var thisProductId = $gridItem.data("product-id");
                return _.contains(filteredProductIds, thisProductId);
              })
              .hide();
        }
      }
      $(document).on('mpp_filter:selected', function(e, filterBy, val) {
        filterGrid(filterBy, val);
      });
      $(document).on('mpp_sort:selected', function(e, sortFn) {
        sortGrid(sortFn);
      });
    },
    attached: false,
  };
})(jQuery);
*/
